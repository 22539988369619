<template>
    <div @click.stop>
        <v-btn
            v-if="showButton"
            @click.stop="togglePanel"
            :block="block"
            :class="buttonClass"
            :height="inline ? 'null' : height"
            :icon="hasIcon && label == null"
            :large="large"
            :small="small"
            :title="title">
            <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
            <v-row v-else-if="!inline" class="mt-0 mb-3">
                <v-col v-if="leftIcon != null" class="mx-0 mx-auto px-auto" cols="12">
                    <v-icon :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
                </v-col>
                <v-col v-if="label != null" class="ma-0 pa-0" cols="12">
                    <div>{{ label }}</div>
                </v-col>
            </v-row>
            <span v-else>
                <v-icon v-if="leftIcon != null" :large="large" :small="small" :left="label != null">{{ leftIcon }}</v-icon>
                {{ label }}
                <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
            </span>
        </v-btn>

        <v-navigation-drawer
            v-model="showPanel"
            app
            :bottom="bottom"
            :clipped="clipped"
            :hide-overlay="hideOverlay"
            right
            style="height: 100%;"
            :width="navWidth">
            <v-toolbar dense>
                <v-btn
                    icon
                    small
                    title="Close"
                    @click.stop="togglePanel">
                    <v-icon>{{ ($vuetify.breakpoint.mobile && bottom) ? 'mdi-arrow-down' : 'mdi-arrow-right' }}</v-icon>
                </v-btn>
                <v-toolbar-title>{{ label }}</v-toolbar-title>
                <v-spacer />
                <slot
                    name="toolbar-right"
                    v-bind:add="add"
                    v-bind:allItems="asyncItems"
                    v-bind:remove="remove"
                    v-bind:patch="patchLink"
                    v-bind:refreshTracking="refreshTracking">
                </slot>
                <v-btn
                    v-if="showEditButton"
                    small
                    title="Edit"
                    @click.stop="isEditing = !isEditing">
                    {{ isEditing ? 'Filter' : 'Show All' }}
                    <!-- <v-icon small>{{ isEditing ? 'mdi-pencil-off' : 'mdi-pencil' }}</v-icon> -->
                </v-btn>
                <v-btn
                    v-if="canRefresh"
                    icon
                    small
                    title="Refresh"
                    @click.stop="refresh">
                    <v-icon small>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>
            <v-toolbar v-if="canSearchLocal || canSearch || showFilters || showSearch" dense>
                <v-btn
                    v-if="canSearchLocal || canSearch"
                    :small="small"
                    icon 
                    @click.stop="showSearch = !showSearch">
                    <v-icon v-if="!showSearch" small>mdi-magnify</v-icon>
                    <v-icon v-else small>mdi-close</v-icon>
                </v-btn>

                <v-slide-x-transition>
                    <v-text-field
                        v-if="(canSearch || canSearchLocal) && showSearch"
                        flat
                        dense
                        hide-details
                        hide-no-data
                        solo-inverted
                        small
                        single-line
                        prepend-inner-icon="mdi-close"
                        append-icon="mdi-magnify"
                        label="Find"
                        v-model="searchInput"
                        @click:prepend-inner.stop="searchInput = null"
                        @click:append.stop="refresh" />
                </v-slide-x-transition>
                
                <v-spacer />

                <v-chip-group
                    v-if="showFilters"
                    v-model="selectedFilters"
                    multiple
                    small>
                    <v-fade-transition hide-on-leave>
                        <v-btn v-if="isFilterChanged"
                            text
                            small
                            @click.stop="refresh">
                            <v-icon small left>mdi-filter</v-icon>Apply
                        </v-btn>
                    </v-fade-transition>
                    <v-chip
                        v-for="(filter, index) in chipFilters"
                        filter
                        outlined
                        small
                        :key="index">
                        {{ filter }}
                    </v-chip>
                </v-chip-group>
            </v-toolbar>
            <v-alert v-model="errorMsg" dismissible type="error">{{ errorMsg }}</v-alert>  

            <v-list 
                v-if="isLengthyArray(filteredItems)"
                dense
                height="auto"
                class="overflow-y-auto">
                <!-- <v-list-item-subtitle v-if="subheader != null">{{ subheader }}</v-list-item-subtitle> -->
                <template v-for="(item, index) in filteredItems">
                    <slot 
                        name="listItem"
                        v-bind:item="item"
                        v-bind:index="index"
                        v-bind:add="add"
                        v-bind:remove="remove"
                        v-bind:patch="patchLink"
                        v-bind:refreshTracking="refreshTracking"
                        v-bind:isEditing="canAuthEdit">
                        <v-list-item
                            :key="index"
                            dense>
                            <slot 
                                v-bind:item="item"
                                v-bind:index="index"
                                v-bind:add="add"
                                v-bind:remove="remove"
                                v-bind:patch="patchLink"
                                v-bind:refreshTracking="refreshTracking"
                                v-bind:isEditing="canAuthEdit">
                                <v-list-item-avatar>
                                     <v-btn
                                        v-if="item.isChanged"
                                        small
                                        :loading="item.loadingCount > 0"
                                        @click.stop="patchLink(item)">
                                        <v-icon small left>mdi-content-save</v-icon> Save
                                    </v-btn>
                                    <v-btn 
                                        v-else-if="(manyToMany && item.link != null) || (!manyToMany && item.manyItem[singleProp] == singleID)"
                                        class="success--text"
                                        icon
                                        :loading="item.loadingCount > 0"
                                        @click.stop="remove(item)">
                                        <v-icon>mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        class="error--text"
                                        icon
                                        :loading="item.loadingCount > 0"
                                        @click.stop="add(item)">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-left">{{ manyText ? getNestedValue(item.manyItem, manyText) : item.manyItem }}</v-list-item-title>
                                </v-list-item-content>
                            </slot>
                        </v-list-item>
                    </slot>
                    <v-divider v-if="dividers" :key="'d' + index" />
                </template>
                <slot name="bottom" />
            </v-list>

            <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>{{ loadingMsg }}</p>
            </v-overlay>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { nestedValue } from '~helpers';
import { firstBy } from 'thenby';

export default {
    name: 'BT-Sidebar-List',
    data: function() {
        return {
            asyncItems: [],
            errorMsg: null,
            isAllLoaded: false,
            isEditing: false,
            isFilterChanged: false,
            isLoaded: false,
            itemJSON: null,
            loadingMsg: null,
            mParams: { includeDetails: false },
            mProxyID: null,
            originalFilters: [],
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            showPanel: false,
            showSearch: false,
            snapshotItemJSON: null,
        }
    },
    props: {
        adjustParams: {
            type: Function,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: 'ma-1'
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSearchLocal: {
            type: Boolean,
            default: true
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        clipped: {
            type: Boolean,
            default: true
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => { return [{ filter: 'Empties', onFilter: (list, propName) => { return list.filter(x => nestedValue(x, propName) == null) } }] } //{ filter: String, onFilter: list => { list }, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        dividers: {
            type: Boolean,
            default: true
        },
        filterProp: {
            type: String,
            default: null
        },
        hideOverlay: {
            type: Boolean,
            default: true
        },
        inline: {
            type: Boolean,
            default: false
        },
        item: null,
        itemProperties: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        leftIcon: {
            type: String,
            default: null
        },
        linkNavigation: {
            type: String,
            default: null
        },
        linkGetURL: {
            type: String,
            default: '/get/Links/{id}' //{id}
        },
        linkParams: {
            type: Object,
            default: null
        },
        linkPostURL: {
            type: String,
            default: '/post/Links/Add'
        },
        linkRemoveByDelete: { //when removing a link - whether to post or delete
            type: Boolean,
            default: false
        },
        linkRemoveURL: {
            type: String,
            default: '/post/Links/Remove'
        },
        manyNavigation: {
            type: String,
            default: null
        },
        manyProp: {
            type: String,
            default: null
        },
        manyText: {
            type: String,
            default: 'text'
        },
        manyToMany: {
            type: Boolean,
            default: false
        },
        navWidth: {
            type: String,
            default: '350'
        },
        onNewLink: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null //() => { return { }}
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resetToggle: { //different to refresh toggle because pagination and default filters are all reset
            type: Boolean,
            default: false
        },
        rightIcon: {
            type: String,
            default: null
        },
        searchProps: {
            type: Array,
            default: null
        },
        showButton: {
            type: Boolean,
            default: true
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        singleID: {
            type: String,
            default: null
        },
        singleProp: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
        sortProp: {
            type: String,
            default: null
        },
        temporary: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        trackChanges: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        asyncItems: {
            deep: true,
            handler() {
                this.updateChange();
            }
        },
        isEditing: function(val) {
            if (val && !this.isAllLoaded) {
                this.pullItems();
            }
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        resetToggle: function() {
            this.reset();
        },
        showPanel: function(val) {
            if (val && !this.isLoaded) {
                this.pullItems();
            }
        },
    },
    created() {
        if (this.params != null) {
            this.mParams = this.params;
        }
    },
    mounted() {
        if (this.params != null) {
            this.mParams = this.params;
        }

        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
            })
            this.originalFilters = this.selectedFilters;
        }
    },
    computed: {
        canAuthEdit() {
            return this.$canEdit(this.linkNavigation);
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customFilters.map(x => x.filter));
            }

            return filterList;
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x.manyItem, this.searchInput, this.searchableProperties));
            }

            if (this.isLengthyArray(this.selectedFilters)) {
                var customFilters = [];

                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                    // else {
                    //     serverFilters.push(sFilter);
                    // }
                });

                var cFilters = this.customFilters.filter(x => x.onFilter != null);
                cFilters.forEach(f => {
                    l = f.onFilter(l, this.singleProp);
                });
            }
            
            l = l.filter(x => x.manyItem.id != this.singleID);

            if (!this.isEditing) {
                if (this.manyToMany) {
                    l = l.filter(x => !(x.link == null || x.link.id == null)); //this.links.some(l => l[this.manyProp] == x.id));
                        //.map(x => { return Object.assign({}, { manyItem: x, link: this.links.find(l => l[this.manyProp] == x.id)})});
                }
                else {
                    l = l.filter(x => this.getNestedValue(x.manyItem, this.singleProp) == this.singleID);
                        //.map(x => { return Object.assign({}, { manyItem: x, link: null })});
                }
            }

            if (this.isLengthyArray(l) && this.sortProp != null) {
                l.sort(firstBy(x => x.manyItem[this.sortProp], { ignoreCase: true }));
            }

            return l;
        },
        hasIcon() {
            return this.leftIcon != null || this.rightIcon != null;
        },
        height() {
            return this.small ? 30 : (this.large ? 80 : 'auto');
        },
        isChanged() {
            return this.itemJSON != this.snapshotItemJSON;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            // else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
            //     return this.bladeData.data[this.proxyIDParam];
            // }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        searchableProperties() {
            return this.searchProps;
        },
        showEditButton() {
            return this.canEdit && this.$BlitzIt.auth.canEdit(this.manyNavigation);
        },
    },
    methods: {
        async add(item) { //many item
            if (this.manyToMany) {
                //var existingLink = item.link; //this.links.find(x => x[this.singleProp] == this.singleID && x[this.manyProp] == item.id);
                if (item.link == null || item.link.id == null) {
                    //add
                    try {
                        item.loadingCount += 1;

                        if (item.link == null) {
                            item.link = { };
                            item.link[this.singleProp] = this.singleID;
                            item.link[this.manyProp] = item.manyItem.id;
                        }
                        
                        var res = await this.$BlitzIt.api.post(this.linkNavigation, item.link, this.proxyCompanyID, this.linkPostURL);
                        if (res.data.data != null) {
                            if (res.data.data.id != null) {
                                item.link.id = res.data.data.id;
                            }
                            if (res.data.data.rowVersion != null) {
                                item.link.rowVersion = res.data.data.rowVersion;
                            }
                        }

                        item.linkJSON = this.createSnapshot(item.link);
                        item.isChanged = false;
                    }
                    catch (err) {
                        this.errorMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        item.loadingCount -= 1;
                    }
                }
            }
            else {
                if (item.manyItem[this.singleProp] != this.singleID) {
                    try {
                        item.loadingCount += 1;

                        var patch = {
                            id: item.manyItem.id,
                        };

                        patch[this.singleProp] = this.singleID;
                        
                        await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch);
                        
                        item.manyItem[this.singleProp] = this.singleID;
                    }
                    catch (err) {
                        this.errorMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        item.loadingCount -= 1;
                    }
                }
            }
        },
        createSnapshot(data) {
            if (data == null) {
                return null;
            }

            var copy = this.copyDeep(data);
            return JSON.stringify(copy);
        },
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            if (this.searchString && this.searchString.length > 0) {
                if (this.canSearch) {
                    paramObj.searchString = this.searchString;
                }
            }
            
            if (this.isLengthyArray(this.selectedFilters)) {
                //var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                });

                if (this.isLengthyArray(customFilters)) {
                    var query = '';
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })

                    if (query.length > 0) {
                        paramObj.query = query;
                    }
                }
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = this.copyDeep(this.itemProperties).toString();
            }

            if (!this.isEditing) {
                if (this.adjustParams != null) {
                    paramObj = this.adjustParams(paramObj, this.singleID);
                }
                else if (this.filterProp != null && this.singleID != null) {
                    paramObj.query = `${this.filterProp}=${this.singleID}`;
                }
            }

            return paramObj;
        },
        // notifyNavigation() {
        //     this.$BlitzIt.navigation.updateSidebar(this.showPanel ? this._uid : null);
        // },
        async patchLink(item) { //many item
            if (this.manyToMany) {
                try {
                    item.loadingCount += 1;

                    if (item.link != null) {
                        var res = await this.$BlitzIt.store.patch(this.linkNavigation, item.link, this.proxyCompanyID);
                        if (res.rowVersion != null) {
                            item.link.rowVersion = res.rowVersion;
                        }
                    }

                    item.isChanged = false;
                    item.linkJSON = this.createSnapshot(item.link);
                }
                catch (err) {
                    this.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    item.loadingCount -= 1;
                }
                
            }
        },
        async pullItems(refresh = false) {
            if (this.manyNavigation == null || this.mParams == null) {
                return;
            }

            if (!this.isEditing && this.isLoaded || this.isEditing && this.isAllLoaded) {
                if (!refresh) {
                    return;
                }
            }

            try {
                this.loadingMsg = 'Pulling Data';
                this.$forceUpdate();

                var paramObj = this.getParamObj();
                var links = [];

                if (this.manyToMany) {
                    var getURL = this.linkGetURL;
                    if (getURL != null) {
                        getURL = getURL.replace('{id}', `${this.singleID}`);
                    }
                    links = await this.$BlitzIt.store.getAll(this.linkNavigation, this.linkParams, refresh, this.proxyCompanyID, getURL);
                    //links = mTRes.data.data;
                }

                var res = await this.$BlitzIt.store.getAll(this.manyNavigation, paramObj, refresh, this.proxyCompanyID, this.customURL);
                
                res = res.map(x => Object.assign({}, { 
                    manyItem: x, 
                    link: this.manyProp != null ? links.find(l => l[this.manyProp] == x.id) : null, 
                    isChanged: false,
                    loadingCount: 0, 
                    errorMsg: null }));
                
                if (this.onNewLink != null) {
                    res.filter(x => x.link == null).forEach(item => {
                        item.link = this.onNewLink(item.manyItem, this.singleID, this.item);
                    })
                }

                if (this.onPullSuccessAsync != null) {
                    res = await this.onPullSuccessAsync(res, refresh); //, this.bladeData);
                }
                // else {
                //     this.asyncItems = res;
                // }

                this.asyncItems = res.map(x => Object.assign(x, { 
                    manyItemJSON: this.createSnapshot(x.manyItem), 
                    linkJSON: this.createSnapshot(x.link) }));
                
                if (this.trackChanges) {
                    this.snapshotItemJSON = this.createSnapshot(this.asyncItems);
                    this.itemJSON = this.snapshotItemJSON;
                }

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;

                if (!this.isEditing) {
                    this.isLoaded = true;
                }
                if (this.isEditing) {
                    this.isAllLoaded = true;
                }
            }
        },
        refreshTracking(item) {
            if (this.manyToMany) {
                item.isChanged = item.linkJSON != this.createSnapshot(item.link);
            }
        },
        refresh(refresh = true) {
            this.searchString = this.searchInput;
            this.showSearch = false;
            this.errorMsg = null;
            this.pullItems(refresh);
        },
        async remove(item) {
            if (this.manyToMany) {
                try {
                    item.loadingCount += 1;
                    
                    if (item.link == null) {
                        item.link = { };
                        item.link[this.singleProp] = this.singleID;
                        item.link[this.manyProp] = item.manyItem.id;
                    }

                    if (this.linkRemoveByDelete) {
                        await this.$BlitzIt.api.delete(this.linkNavigation, item.link, this.proxyCompanyID, this.linkRemoveURL);
                    }
                    else {
                        await this.$BlitzIt.api.post(this.linkNavigation, item.link, this.proxyCompanyID, this.linkRemoveURL);
                    }
                    
                    if (this.onNewLink != null) {
                        item.link = this.onNewLink(item.manyItem, this.singleID);
                    }
                    else {
                        item.link = null;
                    }

                    item.isChanged = false;
                    item.linkJSON = this.createSnapshot(item.link);
                }
                catch (err) {
                    this.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    item.loadingCount -= 1;
                }
            }
            else {
                if (item.manyItem[this.singleProp] == this.singleID) {
                    try {
                        item.loadingCount += 1;

                        var patch = {
                            id: item.manyItem.id,
                        };

                        patch[this.singleProp] = null;

                        var res = await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch);

                        item.manyItem[this.singleProp] = null;
                        
                        if (res != null && res.rowVersion != null) {
                            item.manyItem.rowVersion = res.rowVersion;
                        }
                    }
                    catch (err) {
                        this.errorMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        item.loadingCount -= 1;
                    }
                }
            }
        },
        reset() {
            this.searchString = null;
            this.searchInput = null;
            this.$BlitzIt.store.clear(this.manyNavigation);
            this.pullItems(true);
        },
        togglePanel() {
            this.showPanel = !this.showPanel;
            // if (this.showPanel) {
            //     //hide
            //     this.showPanel = false;
            //     this.$BlitzIt.navigation.updateSidebar(null);
            // }
            // else {
            //     //show
            //     this.showPanel = true;
            //     this.$BlitzIt.navigation.updateSidebar(this._uid);
            // }
        },
        updateChange() {
            this.itemJSON = this.createSnapshot(this.asyncItems);
        },
    }
}
</script>